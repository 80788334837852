import { createAction, props } from '@ngrx/store';
import {
    EditDocumentInput,
    GetDocumentChangeLogsQuery,
    GetProjectDocumentsValidationQuery,
    GetProjectDocumentsValidationQueryVariables,
    LinkDocumentToOrganizationProjectsInput,
    ProjectDocumentsQuery,
    ProjectDocumentsQueryVariables,
    ProtocolsDocumentDefinitionQuery,
    ProtocolsDocumentDefinitionQueryVariables,
    SubmitDocumentInput,
    VerifyDocumentInput
} from 'src/app/@core/generated/operations-core-graphql';
import { ApolloError } from '@apollo/client/core';

export const protocolOrganizationDocumentLoad = createAction('[Document Manager] Grid Load',
    props<{ input: ProjectDocumentsQueryVariables }>());

export const protocolOrganizationDocumentSucess = createAction('[Document Manager] Grid Success',
    props<{ list: ProjectDocumentsQuery }>());

export const protocolOrganizationDocumentFail = createAction('[Document Manager] Grid Fail',
    props<{ errors: any }>());

export const protocolFileUploadDialogOpen = createAction('[Document Manager File Upload] Add Dialog Open',
    props<{ value: boolean }>());

export const protocolSubmitFilesUploadedLoad = createAction('[Document Manager submit Files Upload] Submit Files uploaded',
    props<{ input: SubmitDocumentInput }>());

export const protocolSubmitFilesUploadedSuccess = createAction('[Document Manager submit Files Upload] Submit Files uploaded Success',
    props<{ result: any }>());

export const protocolSubmitFilesUploadedFail = createAction('[Document Manager submit Files Upload] Submit Files uploaded Fail',
    props<{ error: ApolloError }>());

export const protocolClearFileUpload = createAction('[Document Manager submit Files Upload] Clear  new File Uploaded',
    props<{ clear: boolean }>());

export const protocolUpdateFileUpload = createAction('[Document Manager submit Files Upload] Update new File Uploaded',
    props<{ input: { id: '', iconType: string, name: string, nameFile: string, category: string, statusbar: number } }>());

export const protocolDeleteFileUpload = createAction('[Document Manager submit Files Upload] Delete File Uploaded',
    props<{ id: string }>());

//Link  to project

export const protocolLinkToProjectDialogOpen = createAction('[Document Manager File Link/Unlink Project] Add Dialog Open',
    props<{ value: boolean }>());

export const protocolLinkToProjectLoad = createAction('[Document Manager Link Project] Link To Project Load',
    props<{ input: LinkDocumentToOrganizationProjectsInput }>());

export const protocolLinkToProjectSuccess = createAction('[Document Manager Link Project] Link To Project Success',
    props<{ list: any }>());

export const protocolLinkToProjectFail = createAction('[Document Manager Link Project] Link To Project Fail',
    props<{ errors: any }>());

//Edit document

export const protocolEditDocumentDialogOpen = createAction('[Document Manager File Edit] Add Dialog Open',
    props<{ value: boolean }>());

export const protocolEditDocumentLoad = createAction('[Document Manager] Edit Document Load',
    props<{ input: EditDocumentInput }>());

export const protocolEditDocumentSuccess = createAction('[Document Manager] Edit Document Success',
    props<{ result: any }>());

export const protocolEditDocumentFail = createAction('[Document Manager] Edit Document Fail',
    props<{ errors: ApolloError }>());


export const organizationProjectListSelectionChanged = createAction('[Document Manager Organization] Change project selection',
    props<{ input: { id: string, name: string } | null }>());



export const getProtocolDefinitionFiles = createAction('[Document Manager submit Files Upload] Get Protocol Definition Files',
    props<{ variable: ProtocolsDocumentDefinitionQueryVariables }>());

export const getProtocolDefinitionFilesSucess = createAction('[Document Manager submit Files Upload] Get Protocol Definition Files Success',
    props<{ list: ProtocolsDocumentDefinitionQuery }>());

export const getProtocolDefinitionFilesFail = createAction('[Document Manager submit Files Upload] Get Protocol Definition Files Fail',
    props<{ errors: any }>());


export const protocolDocumentLoad = createAction('[Document Manager] Get Document Load',
    props<{ input: { documentId: string } }>());

export const protocolDocumentSuccess = createAction('[Document Manager] Get Document Success',
    props<{ document: any }>());

export const protocolDocumentFail = createAction('[Document Manager] Get Document Fail',
    props<{ errors: any }>());



export const documentChangeLogsLoad = createAction('[Document Manager] Get Document Change Logs Load',
    props<{ input: { documentId: string } }>());

export const documentChangeLogsSuccess = createAction('[Document Manager] Get Document Change Logs Success',
    props<{ data: GetDocumentChangeLogsQuery }>());

export const documentChangeLogsFail = createAction('[Document Manager] Get Document Change Logs Fail',
    props<{ errors: any }>());

export const documentChangeLogsDialogOpen = createAction('[Document Manager] Document Change Logs Dialog Open',
    props<{ value: boolean, documentId: string | null }>());

export const getProjectDocumentsValidation = createAction('[Nerp Organization Quality Assurance] Get Document Validation',
    props<{ variable: GetProjectDocumentsValidationQueryVariables }>());

export const getProjectDocumentsValidationSucess = createAction('[Nerp Organization Quality Assurance] Get Document Validation Success',
    props<{ list: GetProjectDocumentsValidationQuery }>());

export const getProjectDocumentsValidationFail = createAction('[Nerp Organization Quality Assurance] Get Document Validation Fail',
    props<{ errors: any }>());

export const cleanDocumentsValidation = createAction('[Nerp Organization Quality Assurance] cleanDocumentsValidation',
    props<{ Input: boolean }>());

export const cleanDocumentManagerState = createAction('[Document Manager] Clean State');


/*
 *   Documents verification
*/
export const verifyDocumentLoadAction = createAction('[Nerp Organization Quality Assurance] Verify Document Loaded',
    props<{ input: VerifyDocumentInput }>());

export const verifyDocumentSuccessAction = createAction('[Nerp Organization Quality Assurance] Verify Document Success',
    props<{ data: ProjectDocumentsQueryVariables }>());

export const verifyDocumentFailAction = createAction('[Nerp Organization Quality Assurance] Verify Document Fail',
    props<{ errors: any }>());