import { createAction, props } from '@ngrx/store';
import { Crumb } from '@trimble-oss/modus-web-components';
import {
    ProtocolDetailSummaryQuery,
    ProtocolDetailSummaryQueryVariables,
    ProtocolSummary,
    FactorsInput,
    ProtocolTemplate,
    ProtocolDto,
    DeleteOrganizationsFromProtocolInput,
    UsersByProtocolQueryVariables,
    UsersByProtocolQuery,
    UserEmailsQuery, AddUsersToProtocolMutation, DeleteProtocolUsersInput,
    OrganizationDetailQueryVariables,
    OrganizationDetailQuery,
    OrganizationInput,
    AdvisorsQueryVariables,
    AdvisorsQuery,
    PutOrganizationAdvisorInput,
    EditProtocolInput,
    ApiConnectionsByProtocolQuery,
    ApiConnectionsByProtocolQueryVariables,
    ApiConnectionsInput,
    ApiConnectionsDetailByProtocolQuery,
    OrganizationDetail,
    ProjectsByProtocolQuery,
    ProjectDefinitionQuery,
    ProtocolsDocumentDefinitionQueryVariables,
    ProtocolsDocumentDefinitionQuery,
} from '../../../@core/generated/operations-core-graphql';
import {
    CoefficientsGridRow,
    DataSourceEdit,
} from 'src/app/@core/components/ccx-grid-editing.component/model/data-source-editing';
import {
    AddProtocolInput,
    Protocol,
    ProtocolTemplateInput,
    ProtocolsQuery,
    ProtocolsQueryVariables,
    TemplateDetailQuery,
    TemplateDetailQueryVariables,
    TemplatesQuery,
    TemplatesQueryVariables,
    OrganizationsByProtocolQueryVariables,
} from 'src/app/@core/generated/operations-core-graphql';
import {
    GetFeedlotProjectsSummaryQuery,
    GetFeedlotProjectsSummaryQueryVariables
} from 'src/app/@core/generated/operations-protocol-beef-graphql';
import { TemplateResponse } from '../../../models/notifications/TemplateResponse';
import { OrganizationParticipants, ProtocolsGridRow, PutApiConnectionInput } from '../models';
import { ApiConnectionsDetailByProtocolQueryVariables } from '../../../@core/generated/operations-core-graphql';
import { KeyedInput } from 'src/app/@core/functions/key-functions';

export const switchProtocol = createAction(
    '[Protocol] Switched Protocol',
    props<{ protocolSelected: ProtocolDto }>()
);

export const crumbsPages = createAction(
    '[protocol] Switched Pages',
    props<{ crumbs: Crumb[] }>()
);

export const ActiveProtocol = createAction(
    '[protocol Organization] Protocol Active',
    props<{ activeProtocol: string, activeProtocolTemplateName: string }>()
);

export const protocolSwitchTabs = createAction(
    ' switched Tabs',
    props<{ tabName: 'protocol' | 'template' }>()
);

export const protocolChangeView = createAction(
    '[protocol] Change View',
    props<{ activeView: string }>()
);

export const protocolsFail = createAction(
    '[protocol] Table Load Success',
    props<{ protocolList: any }>()
);

export const templatesLoad = createAction(
    '[templates] Table Load',
    props<{ variables: TemplatesQueryVariables }>()
);

export const templatesSucess = createAction(
    '[templates] Table Load Success',
    props<{ templateList: any | null }>()
);
export const templatesFail = createAction(
    '[templates] Table Load Success',
    props<{ templateList: any }>()
);

export const templateDetailLoad = createAction(
    '[Templates Detail] template Detail Load',
    props<{ variables: TemplateDetailQueryVariables }>()
);

export const templateDetailSucess = createAction(
    '[Templates by Name] template Detail Success',
    props<{ templateDetail: ProtocolTemplate | null }>()
);
export const templateDetailFail = createAction(
    '[Templates by Name] template Detail Fail',
    props<{ templateDetail: any }>()
);

export const CleanTemplateDetail = createAction(
    '[Templates Detail] CleanTemplateDetail',
    props<{ templateDetail: ProtocolTemplate }>()
);

export const templateAdd = createAction(
    '[Template] Create Load',
    props<{ input: ProtocolTemplateInput }>()
);

export const templateAddSuccess = createAction(
    '[Template] Create Success',
    props<{ result: any | null }>()
);

export const templateAddFail = createAction(
    '[Template] Create Fail',
    props<{ errors: any }>()
);

export const templateEdit = createAction(
    '[Template] Edit Load',
    props<{ input: ProtocolTemplateInput }>()
);

export const templateEditSuccess = createAction(
    '[Template] Edit Success',
    props<{ result: any | null }>()
);

export const templateEditFail = createAction(
    '[Template] Edit Fail',
    props<{ errors: any }>()
);

export const protTableLoad = createAction(
    '[Prot] Table Load',
    props<{ variables: ProtocolsQueryVariables }>()
);

export const protTableSuccess = createAction(
    '[Prot] Table Load Success',
    props<{ protocolsList: ProtocolsQuery }>()
);

export const selectProtocol = createAction('[Prot] Set Protocol Selected',
    props<{ protocolSelected: ProtocolsGridRow }>());

export const clearProtocolSelected = createAction('[Prot] Clear Protocol Selected');


export const protocolOrganizationLoad = createAction(
    '[protocolOrganization] Table Load',
    props<{ variables: OrganizationsByProtocolQueryVariables }>()
);

export const protocolOrganizationSucess = createAction(
    '[protocolOrganization] Table Load Success',
    props<{ protocolOrganizationList: any | null }>()
);
export const protocolOrganizationFail = createAction(
    '[protocolOrganization] Table Load Fail',
    props<{ errors: any }>()
);
export const templateCoefficentsChange = createAction(
    '[TemplateCoefficent] Template Coefficents Changed',
    props<{ templateSelected: any }>()
);


export const ProtocolAdd = createAction('[Protocol] Create Load',
    props<{ input: AddProtocolInput }>());

export const ProtocolAddSuccess = createAction('[Protocol] Create Success',
    props<{ result: any | null }>());

export const protocolAddFail = createAction('[Protocol] Create Fail',
    props<{ errors: any }>()
);


export const protocolDetailSummaryLoad = createAction(
    '[ProtocolDetailSummary] protocol Detail Summary Load',
    props<{ variables: ProtocolDetailSummaryQueryVariables }>()
);

export const protocolDetailSummarySucess = createAction(
    '[ProtocolDetailSummary] protocol Detail Summary Success',
    props<{ protocolSummary: ProtocolSummary | null }>()
);
export const protocolDetailSummaryFail = createAction(
    '[ProtocolDetailSummary] protocol Detail Summary Fail',
    props<{ ProtocolTemplateFactors: any }>()
);


export const protocolTemplateEditFactor = createAction(
    '[ProtocolTemplateFactors] protocol Template Factors Edit',
    props<{ id: string | null | undefined, name: string, factorType: string, scope: string }>()
);

export const protocolTemplateAddNewFactor = createAction(
    '[ProtocolTemplateFactors] protocol Template Factors Add',
    props<{ scope: number }>()
);

export const protocolTemplateDeleteFactor = createAction(
    '[ProtocolTemplateFactors] protocol Template Factors Delete',
    props<{ id: string | null | undefined }>()
);

export const saveSelectedTemplateDetail = createAction(
    '[ProtocolTemplateFactors] Save Selected Template Detail',
    props<{ id: string | null, name: string }>()
)

export const protocolTemplateEditFactorTabChange = createAction(
    '[ProtocolTemplateFactors] protocol Template Factors Tab Change',
    props<{ tabName: string }>()
);


export const removeOrganizationInProtocol = createAction(
    '[Protocol Organization ] remove organization from protocol Load',
    props<{ input: DeleteOrganizationsFromProtocolInput }>()
);

export const removeOrganizationInProtocolSuccess = createAction(
    '[Protocol Organization ] remove organization from protocol Success',
    props<{ result: any | null }>()
);

export const removeOrganizationInProtocolFail = createAction(
    '[Protocol Organization] remove organization from protocol Fail',
    props<{ errors: any }>()
);

export const protocolUsersTableLoad = createAction('[Protocol User] Users Table Load',
    props<{ variables: UsersByProtocolQueryVariables }>());

export const protocolUsersTableLoadSuccess = createAction('[Protocol User] Users Load Success',
    props<{ usersList: UsersByProtocolQuery }>()
);

export const protocolUsersTableLoadFails = createAction(
    '[Protocol User] Users Load Fail',
    props<{ errors: any }>());

export const submitDeleteProtocolUserLoad = createAction('[Protocol User] Submit Delete Protocol User Load',
    props<{ input: DeleteProtocolUsersInput[] }>()
);

export const submitDeleteProtocolUserSuccess = createAction('[Protocol User] Submit Delete Protocol User Success',
    props<{ result: any, template: TemplateResponse | undefined }>());

export const submitDeleteProtocolUserFail = createAction('[Protocol User] Submit Delete Protocol User Fail',
    props<{ errors: any }>());


export const putOrgLoad = createAction('[Protocol Organization] Create Load',
    props<{ input: OrganizationInput }>());

export const putOrgSuccess = createAction('[Protocol Organization] Create Success',
    props<{ result: any | null }>());

export const putOrgFail = createAction('[Protocol Organization] Create Fail',
    props<{ errors: any }>());

export const setCurrentOrganization = createAction('[Protocol Organization] Current Organization Details Load',
    props<{ variables: OrganizationDetailQueryVariables }>());

export const setCurrentOrganizationSuccess = createAction('[Protocol Organization] Current Organization Details Load Success',
    props<{ organizationDetail: OrganizationDetail }>());

export const setCurrentOrganizationFail = createAction('[Protocol Organization] Current Organization Details Load Fail',
    props<{ errors: any }>());

export const cleanCurrentOrganizationState = createAction('[Protocol Organization] Clean Current Organization State');

export const getAdvisorsLoad = createAction('[Organization Advisor] Get Advisors Load',
    props<{ variables: AdvisorsQueryVariables }>());

export const getAdvisorsSuccess = createAction('[Organization Advisor] Get Advisors Success',
    props<{ advisorList: AdvisorsQuery }>()
);
export const getAdvisorsFail = createAction('[Organization Advisor] Get Advisors Fail',
    props<{ errors: any }>()
);

export const PutOrganizationAdvisor = createAction('[Organization Advisor] put Advisor Organization ',
    props<{ input: PutOrganizationAdvisorInput }>());

export const PutOrganizationAdvisorSuccess = createAction('[Organization Advisor] put Advisor Organization Success',
    props<{ result: any | null }>());

export const PutOrganizationAdvisorFail = createAction('[Organization Advisor] put Advisor Organization Fail',
    props<{ errors: any }>());

export const AddAdvisorsDialogOpen = createAction('[Organization Advisor] Add Dialog Open',
    props<{ value: boolean }>());

export const SetOrgInformationForAdvisor = createAction('[Organization Advisor] set organization information',
    props<{ orgId: string, orgName: string, protocolId: string }>());



export const editProtocolLoad = createAction('[Protocol] Edit Protocol Load',
    props<{ input: EditProtocolInput }>());

export const editProtocolSuccess = createAction('[Protocol] Edit Protocol Success',
    props<{ result: any | null }>());


export const editProtocolFail = createAction('[Protocol] Edit Protocol Fail',
    props<{ errors: any }>()
);

export const protocolApiConnsLoad = createAction('[Protocol] Api Connections Load',
    props<{ input: ApiConnectionsByProtocolQueryVariables }>());

export const protocolApiConnsLoadSuccess = createAction('[Protocol] Api Connections Load Success',
    props<{ apiConnectionsList: ApiConnectionsByProtocolQuery | null }>());


export const protocolApiConnsLoadFail = createAction('[Protocol] Api Connections Load Fail',
    props<{ errors: any }>()
);

export const AddOrgParticipantDialogOpen = createAction('[Api Connections] Add Dialog Open',
    props<{ value: boolean }>());

export const SetInformationApiConnectionParticipantOrg = createAction(' [Api Connections]  set information Api Connection to Participant Org',
    props<{ input: OrganizationParticipants[] }>());

export const ParticipantOrganizationFail = createAction('[Api Connections]  Participant Organizations Fail',
    props<{ errors: any }>()
);
export const apiConnectionPut = createAction('[Api Connections] Put ApiConnection',
    props<{ apiConnection: ApiConnectionsInput }>());

export const apiConnectionPutSuccess = createAction('[Api Connections] Put ApiConnection Success',
    props<{ result: any | null }>());

export const apiConnectionPutFail = createAction('[Api Connections] Put ApiConnection Fail',
    props<{ errors: any }>());


export const apiConnectionDetailLoad = createAction('[Api Connections]  ApiConnection Detail Load',
    props<{ variables: ApiConnectionsDetailByProtocolQueryVariables }>());

export const apiConnectionDetailSuccess = createAction('[Api Connections] ApiConnection Detail Success',
    props<{ apiConnectionDetail: ApiConnectionsDetailByProtocolQuery | null }>());

export const apiConnectionDetailFail = createAction('[Api Connections] ApiConnection Detail Fail',
    props<{ errors: any }>());

export const OrganizationParticipantDeleteId = createAction('[Api Connections] Delete row Percentage',
    props<{ organizationId: string | null | undefined }>()
);

export const cleanDetailApiConnection = createAction(' [Api Connections]  reset Detail Api Connection information',
    props<{ input: any }>());

export const setIsWorking = createAction(' [Api Connections]  is working Api Connection information',
    props<{ input: boolean }>());


export const protocolProjectsLoad = createAction('[Protocol Projects] Protocol Projects Load',
    props<{ protocolId: string }>());

export const protocolProjectsLoadSuccess = createAction('[Protocol Projects] Protocol Projects Load Success',
    props<{ result: ProjectsByProtocolQuery | null }>());

export const protocolProjectsLoadFail = createAction('[Protocol Projects] Protocol Projects Load Fail',
    props<{ errors: any }>());

    export const setProtocolFactorsByTemplate = createAction('[ Protocol Factors] Factors Load',
        props<{ variables: any }>());
    
export const putCoefficient = createAction('[Protocol Coefficient Editor] Add Coefficient',
    props<{ coefficient: KeyedInput & Record<string, string>, action: string }>());

export const deleteCoefficient = createAction('[Protocol  Coefficient Editor] Delete Coefficient',
    props<{ id: string }>());

    export const getProtocolDefinitionFiles = createAction('[Nerp Organization Document submit Files Upload] Get Protocol Definition Files',
        props<{ variable:  ProtocolsDocumentDefinitionQueryVariables}>());
    
    export const getProtocolDefinitionFilesSucess = createAction('[Nerp Organization Document submit Files Upload] Get Protocol Definition Files Success',
        props<{ list: ProtocolsDocumentDefinitionQuery }>());
    
    export const getProtocolDefinitionFilesFail = createAction('[Nerp Organization Document submit Files Upload] Get Protocol Definition Files Fail',
        props<{ errors: any }>());
    
    